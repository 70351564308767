// More spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375)  //150px
  ),
  $spacers
);

// Reduce text size
$font-size-base: 0.9rem;
$line-height-base: 1.3;

@import "~bootstrap/scss/bootstrap";

html, body, #root {
  height: 100% !important;
}
a.unstyled {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline;
  }
}

.btn-tab-top {
  position: relative;
  top: -5px;
}

.nolabel-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}
.react-datepicker-popper {
  z-index: 100 !important;
}
.animate-spinner {
  animation-name: animate-spinner-keyframes;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
  display: inline-block;
}
@keyframes animate-spinner-keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-tiny {
  --bs-btn-padding-y: .05rem;
  --bs-btn-padding-x: .3rem;
  --bs-btn-font-size: .75rem;
  padding-top: 2px;
  border-radius: 3px;
}
.table-sm {
  font-size: 90%;
}
table {
  td {
    &.shrink {
      width: 1%;
      white-space: nowrap;
    }
    &.treatments {
      background-color: rgba(214,254,254,0.5);
    }
    &.treatments-total {
      background-color: rgba(214,254,254,0.9);
      font-style: italic;
    }
    &.population {
      background-color: rgba(207,255,203,0.5);
    }
    &.endemicity {
      background-color: rgba(253,204,152,0.5);
    }
    &.shipstatus {
      background-color: rgba(253,204,152,0.5);
    }
  }
  .GenericSearchTableHeader {
    .input-group {
      flex-wrap: nowrap;
      input {
        min-width: 50px;
      }
    }
  }
}

